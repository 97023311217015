import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/static/css/swiper.min.css'
import appDown from "./components/appDown" //下载弹窗
import openServer from "./components/openServer" //开服信息弹窗
export default {
    name:'Detail',
    data() {
    return {
        dialogVisible:false, //开服弹窗开关
        detailDownDialog:false, //下载弹窗开关
        detail:{},
        bg_pic:'',
        showMoreServel:false,
        openServerList:[], //开服列表
        openServerListAll:[], //全部开服信息
        fivePicList:[],
        number_czc:1,
        swiperOptions: {
          pagination: {
            el: '.swiper-pagination'       
          },
          loop: false, //循环模式
          speed: 200,
          slidesPerView: 1.5,
          centeredSlides: false,
          watchSlidesProgress: true,
          initialSlide: 0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true,//修改swiper的父元素时，自动初始化swiper
          on: {}
          // Some Swiper option/callback...
        }
      };
    },
    components:{
      Swiper,
      SwiperSlide,
      appDown,
      openServer
    },
    directives: {
      swiper: directive
    },
    watch: {     
      'id'(val,oldVal) {
        if(val != oldVal){
          this.getDetail()
        }
      }
    }, 
    computed:{
      id:function(){
        return parseInt(this.$route.params.id)
      },
      starWidth:function(){
        return this.detail.ratings*20
      },
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },
    mounted() {
      //this.swiper.slideTo(3, 1000, false)
    },
    created(){
      this.getDetail()
      window.czc.push(['_setAccount', window.ymId])
      window.czc.push(['_trackPageview', '官网_游戏详情页'])
    },
    methods:{
      closeAppDown(){
        window.czc.push(['_trackEvent', '官网_游戏详情页', '官网_游戏详情页_点击下载', '', '', this.number_czc])
        this.number_czc++
        this.detailDownDialog = false
      },
      closeOpenServer(){
        window.czc.push(['_trackEvent', '官网_游戏详情页', '官网_游戏详情页_查看开服列表“全部”', '', '', this.number_czc])
        this.number_czc++
        this.dialogVisible = false
      },
      formatDate(date2,type) {
        let data = ""
        let date = new Date(date2*1000)
        let YY = date.getFullYear() + '.'
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.'
        let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        //let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        if(type){
          data = YY + MM + DD;
        }else{
          data = YY + MM + DD + " " + hh + mm;
        }
        return data;
      },
      formatServerDate(date2) {
        let data = ""
        let date = new Date(date2*1000)
        //let YY = date.getFullYear() + '.'
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
        let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        //let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
        
        data = MM + '月' + DD + "日<span style='margin:0 3px;color: #a2a9b3;font-size: 11px;'>|</span>" + hh +":"+ mm;
        return data;
      },
      isToday(str) {   
        return new Date().toDateString() === new Date(str).toDateString()
      },
      getDetail(){
        
        this.openServerList = []
        this.openServerListAll = []
        let options = {
          id: this.id
        }
        this.$fetch('/index.php?g=api&m=game&a=detail',options)
        .then((response) => {
          let data = response.data
          this.detail = data
          this.bg_pic = this.detail.pics && this.detail.pics.bg_pic ? this.detail.pics.bg_pic : ''
          if(this.$route.name === 'Detail'){
            document.title = `${this.detail.name} -- ${this.detail.brief}`
          }
          
          if(this.detail.latest_server_info && this.detail.latest_server_info.server_id){
            this.getOpenServe()
          }
          this.fivePicList = []
          for(let key in this.detail.pics){
            if(key.includes('mat_pic') && this.detail.pics[key] && (this.detail.pics[key].includes('png') || this.detail.pics[key].includes('jpg'))){
              this.fivePicList.push(this.detail.pics[key])
            }
          }
        })
      },
      showAllServe(){
        this.dialogVisible = true
      },
      getOpenServe(){
        this.openServerList = []
        this.openServerListAll = []
        let options = {
          gid: this.id
        }
        this.$fetch('/api-game-gameOpenServer',options)
        .then((response) => {
          let data = response.data
          this.openServerList = data.slice(0,4)
          this.openServerListAll = data      
        })
      },
      discountText(discount,label_extra_desc){
        if(discount < 10){
          return `<span class="font-family-din">${discount}</span>折充值`        
        }else{
          if(label_extra_desc){
            return label_extra_desc
          }else{
            return `<span class="font-family-din">${discount}</span>折充值`
          }
        }
      }
    }
}