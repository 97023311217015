<template>
  <div class="footer">
    <div class="max-width clearfloat">
      <div class="footer-left">
        <div class="footer-nav clearfloat">
          <a class="footer-link" v-for="(item,index) in list" :key="index" @click="go(item)">{{item.text}}</a>
          <!-- <a class="footer-link">未成年人家长监护</a> -->
          <!-- <a class="footer-link">隐私政策</a> -->
          <!-- <a class="footer-link">侵权投诉</a> -->
        </div>
        <div class="footer-des">
          <p>适龄提示：本公司产品适合10周岁以上玩家使用 | <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备18004392号</a> | <a target="_blank" :href="require('@/assets/about/network.png')">琼网文[2019]1322-103号</a> | <a target="_blank" :href="require('@/assets/about/license.png')">经营许可证</a> | <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006976"><img style="vertical-align: text-bottom;" width="14px" src="@/assets/ba.png" /> 粤公网安备 44030502006976号</a></p>
          <p>海南爱喵游网络科技有限公司 | 公司地址：广东省深圳市南山区科苑南路3099号中国储能大厦第23层 | 注册地址：海南省澄迈县老城镇老城高新技术产业示范区海南生态软件园A17幢一层2001</p>
          <p>违法信息举报电话：0755-88111710 | <a href="http://szwljb.sz.gov.cn/" target="_blank">网上有害信息举报专区</a></p>
          <p class="copyright">Copyright &#169; Orange Game. All rights Reserve</p>
        </div>
      </div>
      <div class="footer-right">
        <div class="clearfloat">
          <el-popover
            placement="top"
            width="150"
            trigger="hover">
            <div style="text-align:center"><img width="120" src="@/assets/wx.jpg" alt=""></div>
            <div class="img-ico" slot="reference">
              <img src="@/assets/header/weixinw.png" alt="">
            </div>
          </el-popover>
          <el-popover
            placement="top"
            width="120"
            trigger="hover">
            <div style="text-align:center">3269945629</div>
            <div class="img-ico" slot="reference">
              <img src="@/assets/header/qqw.png" alt="">
            </div>
          </el-popover>
          
          <!-- <div class="img-ico last">
            <img src="@/assets/header/weibo.png" alt="">
          </div> -->
        </div>
        <dl>
          <dt>温馨提示</dt>
          <dd>抵制不良游戏，拒绝盗版游戏<br />注意自我保护，谨防上当受骗<br />
          适度游戏益脑，沉迷游戏伤身</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      number_czc:1,
      list:[
        {
          text:'关于我们',
          link:'/pc/about',
          data:'官网_点击底部功能区“关于我们”'
        },
        {
          text:'联系我们',
          link:'/pc/about/contact',
          data:'官网_点击底部功能区“联系我们”'
        }
      ]
    }
  },
  computed:{
   
  },
  methods: {   
    go(item){
      window.czc.push(['_trackEvent', '官网_关于我们页面', item.data, '', '', this.number_czc])
      this.number_czc++
      window.open(item.link)
    } 
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer{
  background-color:#0a0723;
  height: 276px;
  color:rgba(255,255,255,0.6);  
  box-sizing: border-box;
  padding-top: 50px;
}
.footer-left{
  float: left;
}
.footer-nav{
  .footer-link{
    font-size: 18px;
    float:left;
    margin-right: 70px;
    color: #fff;
    text-decoration:none;
    cursor: pointer;
    &:hover{
      color: #fe3a3d
    }
  }
}
.footer-des{
  padding-top: 28px;
  font-size: 11px;
  a{
    text-decoration: none;
    color:rgba(255,255,255,0.6); 
    &:hover{
      color:rgba(255,255,255,1);  
    }
  }
  
  p{
    line-height: 13px;
    margin-top: 12px;
  }
  p.copyright{
    margin-top: 40px;
    margin-bottom: 0;
  }
}
.footer-right{
  float: left;
}
.footer-right{  
  width: 170px;
  float: right;
  dl{
    margin-top: 47px;
    opacity: 0.6;
    dt{
      font-size: 14px;
      margin-bottom: 12px;
    }
    dd{
      font-size: 11px;
      line-height: 19px;
    }
  }
  .img-ico{
    width: 44px;
    height: 44px; 
    border-radius: 50%;
    overflow: hidden;
    margin-right: 40px;
    float: left;
    &:hover{
      cursor: pointer;
      img{
        opacity: 1;
      }
    }
    img{
      width: 44px;
      opacity: 0.6;
    }
  }
  .last{
    margin-right: 0;
  }
}
</style>
