<template>
  <div>
    <div class="index-warp max-width clearfloat">
      <div class="index-warp-left">
        <el-carousel height="350px" :initial-index="bannderIndex" :interval="5000" class="indexPic" v-if="bannerList.length > 0">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div @click="bannerGo(item)" class="indexPic-img">
              <img :src="item.slide_pic" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="game-part" style="padding-bottom:20px;">
          <div class="game-part-title">
            {{newGameList.title}}
          </div>
          <el-carousel class="indexNew" :autoplay="false" :interval="5000" height="300px" arrow="always">
            <el-carousel-item v-for="(item,index) in newGameList.gameList" :key="index">
              <div class="game-list clearfloat">
                <div @click="goDetail(i.id,1)" :class="k == 3 ? 'game-li game-li-right' :( k == 0 ? 'game-li game-li-index' : 'game-li')" v-for="(i,k) in item" :key="k">
                  <div class="game-li-top">
                    <img :src="i.pics.activity_pic" alt="">
                    <div :class="i.discount < 10  ? 'game-li-tag game-li-tagR' : 'game-li-tag game-li-tagB' " v-html="discountText(i.discount,i.label_extra_desc)"></div>
                  </div>
                  <div class="game-li-text">
                    <div class="t">{{i.name}}</div>
                    <div class="p">{{i.brief}}</div>
                    <div class="tag">
                      <template v-for="(p,d) in i.labels">
                        <span :key="d" v-if="d < 4"><span v-if="d != 0"><b> / </b></span>{{p.name}}</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 编辑精选 -->
        <div class="game-part">
          <div class="game-part-title">
            {{goodList.title}}
          </div>
          <div class="game-list clearfloat">
            <div @click="goDetail(i.id,2)" :class="k == 3 ? 'game-li game-li-right' :'game-li'" v-for="(i,k) in goodList.games" :key="k">
              <div class="game-li-top">
                <img :src="i.pics.activity_pic" :alt="i.name">
                <div :class="i.discount < 10  ? 'game-li-tag game-li-tagR' : 'game-li-tag game-li-tagB' " v-html="discountText(i.discount,i.label_extra_desc)"></div>
              </div>
              <div class="game-li-text">
                <div class="t">{{i.name}}</div>
                <div class="p">{{i.brief}}</div>
                <div class="tag">
                  <template v-for="(p,d) in i.labels">
                    <span :key="d" v-if="d < 4"><span v-if="d != 0"><b> / </b></span>{{p.name}}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 编辑精选 -->
        <!-- 其他 -->
        <div class="game-part" v-for="(item,index) in otherList" :key="index">
          <div class="game-part-title">
            {{item.title}}
          </div>
          <div class="game-list clearfloat">
            <template v-for="(i,k) in item.games">
              <div @click="goDetailOther(i.id,index,k)" v-if="k == 0" class="game-li-big" :key="k" :style="{backgroundImage: 'url(' + (i.videos.main.cover_url ? i.videos.main.cover_url : i.pics.mat_pic1) + ')'}">
                <!-- <img :src="i.videos.main.cover_url" alt=""> -->
                <div class="game-li-mask"></div>
                <div :class="i.discount < 10  ? 'game-li-tag game-li-tagR' : 'game-li-tag game-li-tagB'" v-html="discountText(i.discount,i.label_extra_desc)"></div>
                <div class="t clearfloat">
                  <div class="t1">{{i.name}}</div>
                  <div class="tag">
                    <template v-for="(p,d) in i.labels">
                      <span :key="d" v-if="d < 4">{{p.name}}</span>
                    </template>
                  </div>
                </div>
                <div class="p">{{i.brief}}</div>
                <div class="view">查看详情</div>
              </div>
            </template>
            <template v-for="(i,k) in item.games">
              <div @click="goDetailOther(i.id,index,k)" v-if="k > 0 && k < 3" :class="k == 2 ? 'game-li game-li-right' :'game-li'" :key="k">
                <div class="game-li-top">
                  <img :src="i.pics.activity_pic" :alt="i.name">
                  <div :class="i.discount < 10  ? 'game-li-tag game-li-tagR' : 'game-li-tag game-li-tagB' " v-html="discountText(i.discount,i.label_extra_desc)"></div>
                </div>
                <div class="game-li-text">
                  <div class="t">{{i.name}}</div>
                  <div class="p">{{i.brief}}</div>
                  <div class="tag">
                    <template v-for="(p,d) in i.labels">
                      <span :key="d" v-if="d < 4"><span v-if="d != 0"><b> / </b></span>{{p.name}}</span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- 其他 -->
      </div>
      <div class="index-warp-right">
        <div class="index-down">
          <img src="@/assets/orange.png" alt="">
          <div class="t">下载橙子游戏APP</div>
          <div class="p">专业手游折扣平台</div>
        </div>
      </div>
    </div>
    <app-down></app-down>
  </div>
  
</template>
<script>
import index from './index.js'
export default index
</script>
<style lang="scss" scoped>
@import "@/static/css/global.scss";
@import "./style.scss";
</style>
<style lang="scss">
.indexPic .el-carousel{
  position: relative;
}
.el-carousel__container{
  border-radius: 9px;
  overflow: hidden;
}
.indexPic .el-carousel__indicators--horizontal{
  position: absolute;
  right: 30px;
  bottom: 28px;
  left: auto;
}
.indexPic ul li{
  padding: 0 4px;
}
.indexPic ul li .el-carousel__button{
  width: 9px;
  height: 6px;
  opacity: 0.5;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 5px 20px 0px rgba(173,173,173,0.30); 

}
.indexPic ul li.is-active .el-carousel__button{
  opacity: 1;
}
.indexPic .el-carousel__item {  
  overflow: hidden;
  border-radius: 9px;
  img{
    width: 916px;
    overflow: hidden;
    border-radius: 9px;
  }
}    
</style>

<style lang="scss">
.indexNew ul li{
  padding: 0 4px;
}
.indexNew .el-carousel__item {
      background: #fafafa;
}
.indexNew ul li .el-carousel__button{
  width: 9px;
  height: 6px;
  opacity: 0.2;
  background: #a2a9b3;
  border-radius: 3px;

}
.indexNew ul li.is-active .el-carousel__button{
  opacity: 1;
}
.indexNew .el-carousel__indicators--horizontal{
  bottom: 19px;
}
.indexNew .el-carousel__arrow{
  width: 24px;
  height: 24px;
  top:auto;
  bottom:0px;
  background: #a2a9b3;
  &:hover{
    background: linear-gradient(90deg,#ff4b21, #fd5537 18%, #ee3747);
  }
}
.indexNew .el-carousel__arrow--left{
  left: 50%;
  margin-left: -72px;
}
.indexNew .el-carousel__arrow--right{
  right: 50%;
  margin-right: -72px;
}
</style>