import axios from 'axios'
import Qs from "qs"; // 引入qs模块，用来序列化post类型的数据
import { autoMatch, checkStatus } from "./utils"; // 附近处理函数
//import qs Form 'qs'
axios.defaults.timeout = 5000;
//axios.defaults.baseURL ='//box.52game.com';
//let protocol = window.location.protocol; //协议
let host = window.location.host; //主机

if(host.includes('25wanwan')) {
  //若本地项目调试使用
  axios.defaults.baseURL = `//box.25wanwan.com`;    
} else {
  //动态请求地址             协议               主机
  axios.defaults.baseURL = '//box.52game.com';
}

//http request 拦截器
// axios.interceptors.request.use(
//   config => {
//     // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
//     // config.data = JSON.stringify(config.data);
//     config.headers = {
//       'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'
//     }
//     // if(token){
//     //   config.params = {'token':token}
//     // }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );


//http response 拦截器
// axios.interceptors.response.use(
//   response => {
//     if(response.data.errCode ==2){
//     //   router.push({
//     //     path:"/login",
//     //     query:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
//     //   })
//     }
//     return response;
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url,params={}){
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url,data = {}){
   return new Promise((resolve,reject) => {
     axios.post(url,data)
          .then(response => {
            resolve(response.data);
          },err => {
            reject(err)
          })
   })
 }

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */




//import { Toast } from "mint-ui"; // 提示框

// 创建axios实例
const instance = axios.create({
  // baseURL: process.env.BASE_URL,
  timeout: 30000, // 请求超时时间
  // `transformRequest` 允许在向服务器发送前，修改请求数据
  transformRequest: [
    function(data) {
      // 对 data 进行任意转换处理
      return data;
    }
  ],
  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse: [
    function(data) {
      // 对 data 进行任意转换处理
      return JSON.parse(data);
    }
  ]
});

// 实例添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    // 在发送请求之前做处理...
    config.headers = Object.assign(
      config.method === "get"
        ? {
            Accept: "application/json",
            "Content-Type": "application/json; charset=UTF-8"
          }
        : {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
      config.headers
    );
    if (config.method === "post") {
      const contentType = config.headers["Content-Type"];
      // 根据Content-Type转换data格式
      if (contentType) {
        if (contentType.includes("multipart")) {
          // 类型 'multipart/form-data;'
          // config.data = data;
        } else if (contentType.includes("json")) {
          // 类型 'application/json;'
          // 服务器收到的raw body(原始数据) "{name:"nowThen",age:"18"}"（普通字符串）
          config.data = JSON.stringify(config.data);
        } else {
          // 类型 'application/x-www-form-urlencoded;'
          // 服务器收到的raw body(原始数据) name=nowThen&age=18
          config.data = Qs.stringify(config.data);
        }
      }
    }
    return Promise.resolve(config);
  },
  function(error) {
    // 对请求错误做处理...
    return Promise.reject(error);
  }
);

// 实例添加响应拦截器
instance.interceptors.response.use(
  
  function(response) {
    // 对响应数据做处理，以下根据实际数据结构改动！！...
    // const { reason_code } = response.data || {};
    // if (reason_code === '001') { // 请求超时，跳转登录页
    //   const instance = Toast('请求超时，即将跳转到登录页面...');
    //   setTimeout(() => {
    //     instance.close();
    //     window.location.href = '/login';
    //   }, 3000)
    // }
    return Promise.resolve(checkStatus(response));
  },
  function(error) {
    // 对响应错误做处理...
    if (error.response) {
        if(error.response.status == '401'){
            localStorage.removeItem('userInfo')
            return Promise.reject(checkStatus(error.response));
        }else if (error.code == "ECONNABORTED" && error.message.indexOf("timeout") != -1) {
            return Promise.reject({ msg: "请求超时" });
        } else if(!error){
        // router.push({
        //   name:'Conflict'       
        // })
        }else {
            console.log(error,123456)
            return Promise.reject({});
        }
    }
})

const request = async function(opt) {
  try {
    const options = Object.assign(
      {
        method: "get",
        ifHandleError: true, // 是否统一处理接口失败(提示)
      },
      opt
    );
    // 匹配接口前缀 开发环境则通过proxy配置转发请求； 生产环境根据实际配置
    options.baseURL = autoMatch(options.prefix);
    const res = await instance(options);
    // if (!res.data && options.ifHandleError) {
    //   // 自定义参数，是否允许全局提示错误信息
    //   //Toast(res.error || "请求处理失败！");
    //   this.$toast.center(res.msg || "请求处理失败！")
    // }
    return res;
  } catch (err) {
    // if (options.ifHandleError) {
    //   // 自定义参数，是否允许全局提示错误信息
    //   //Toast(err.msg || "请求处理失败！");
    //   this.$toast.center(err.msg || "请求处理失败！")
    // }
    // return err;
  }
};

export default request