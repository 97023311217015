<template>
  <div id="app">
    <keep-alive>
      <router-view name="header"></router-view>
    </keep-alive>
    <div class="content">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <keep-alive>
      <router-view name="footer"></router-view>
    </keep-alive>
    
  </div>
</template>
<script>
import "./static/css/reset.css";
import "./static/css/global.scss";
// import Header from "@/components/header";
// import Footer from "@/components/footer";
// import AuthApi from "@/api/auth";
// import { setFrame } from "@/utils";
export default {
  name: "App",
  data() {
    return {
      title: "用户中心",
      isRouterAlive: true    
    };
  },
  // components: { Header, Footer },
  created() {
    
  },
  methods: {}
    
}
</script>

<style lang="scss" scoped>
@import "./static/css/global.scss";
</style>
