<template>
  <el-dialog
    title="全部开服信息"
    :visible.sync="isShow"
    :before-close="closeAppDown"    
    center
    width="500px">
    <div>
      <el-table
        :data="list"
        height="500"
        stripe
        style="width: 100%">
        <el-table-column           
          label="开服时间">
          <template slot-scope="scope">
          <span v-html="formatServerDate(scope.row.start_time)"></span><span class="today-span" v-if="isToday(scope.row.start_time*1000)">今日</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="server_name"
          label="服务器"
        >
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    isShow: {
      type: Boolean
    },
    list: {
      type: Array
    }
  },
  computed:{
    
  },
  methods: { 
    closeAppDown(){
      this.$emit('closeAppDown')
    },
    isToday(str) {   
      return new Date().toDateString() === new Date(str).toDateString()
    },
    formatServerDate(date2) {
      let data = ""
      let date = new Date(date2*1000)
      //let YY = date.getFullYear() + '.'
      let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
      let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      //let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      
      data = MM + '月' + DD + "日<span style='margin:0 3px;color: #a2a9b3;font-size: 11px;'>|</span>" + hh +":"+ mm;
      return data;
    },
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
  .table-h{
    max-height: 450px;
    overflow-y: auto;
  }
    .today-span{
      width: 28px;
      height: 14px;
      background: #ffebe6;
      border: 1px solid rgba(254,58,61,0.50);
      border-radius: 2px;
      line-height: 14px;
      font-size: 10px;
      color: #fe3a3d;
      text-align: center;
      margin-left: 5px;
    }
    ::v-deep .el-dialog{
    border-radius: 12px;
  }
    ::v-deep .el-dialog__title{
    font-size: 22px;
    color: #070d0f;
  }
    ::v-deep .el-dialog__header{
    padding-top: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ededee;
  }
  ::v-deep .el-dialog__body{
    padding:20px;
  }
   ::v-deep .el-table th.is-leaf{
    background: #fafafa;
    border-bottom: 1px solid #ededee;
  }
    ::v-deep .el-table td, .el-table th{
    font-size: 14px;
    color: #070d0f;
    padding:8px 0;
  }
</style>
