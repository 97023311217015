<template>
  <div>
    <div class="bg1" v-for="(img,i) in imgList" :key="i" v-lazy:background-image="img.src" :style="{backgroundColor: img.bgColor}">
    </div>  
    <app-down></app-down>  
  </div>
  
</template>

<script>
import AppDown from "@/components/index/app";
export default {
    name:'Index',
     data() {
      return {
        imgList:[
           {
            src:require("@/assets/app/5.png"),
            bgColor:'#FE7757'
          },
          {
            src:require("@/assets/app/1.png"),
            bgColor:'#f5f5f5'
          },
          {
            src:require("@/assets/app/2.png"),
            bgColor:'#ffffff'
          },
          {
            src:require("@/assets/app/3.png"),
            bgColor:'#f5f5f5'
          },
          {
            src:require("@/assets/app/4.png"),
            bgColor:'#ffffff'
          }
        ]
      }
    },
    components:{
        AppDown
    },
    created(){
      window.czc.push(['_setAccount', window.ymId])
      window.czc.push(['_trackPageview', '官网_下载app页面'])
    },
    methods:{
        
    }
}
</script>
<style lang="scss" scoped>
.bg1{
  height: 600px;
  background-repeat: no-repeat;
  background-position: top center;
}
</style>