import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/pages/index/index.vue' //用户中心
import About from '@/pages/about/index.vue' //用户中心
import Contact from '@/pages/about/contact.vue'
import License from '@/pages/about/license.vue'
import Network from '@/pages/about/network.vue'
import App from '@/pages/app' //用户中心
import Detail from '@/pages/detail' //用户中心
import Login from '@/login/index.vue' //用户中心

import Header from "@/components/header";
import Footer from "@/components/footer";


const components = (component)=>{
  return {
    default:component,
    header:Header,
    footer:Footer
  }
}

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition
  },
  routes: [
    {
      path: '/',  // 默认进入路由
      redirect: '/pc/index'   //重定向
    },
    {
      path: '/pc/index',
      name: 'Index',
      components: components(Index),
      meta:{
        title: '首页',
        index: 0
      }
    },
    {
      path: '/pc/about',
      name: 'About',
      components: components(About),
      meta:{
        title: '关于我们',
        index: 1,
        nav:0,
      }    
    },
    {
      path:'/pc/about/contact',
      name:'Contact',
      components:components(Contact),
      meta:{
        title: '联系我们',
        index: 1,
        nav:1,
      }
    },
    {
        path:'/pc/about/contact',
        name:'Contact',
        components:components(Contact),
        meta:{
          title: '联系我们',
          index: 1,
          nav:1,
        }
    },
    {
      path:'/pc/about/network',
      name:'Network',
      components:components(Network),
      meta:{
        title: '网络文化经营许可证',
        index: 1
      }
    },
    {
      path:'/pc/about/license',
      name:'License',
      components:components(License),
      meta:{
        title: '经营许可证',
        index: 1,
      }
    },
    {
      path: '/pc/app',
      name: 'App',
      components: components(App),
      meta:{
        title: 'APP下载',
        index: 2
      }
    },
    {
      path: '/pc/detail/:id',
      name: 'Detail',
      components: components(Detail),
      meta:{
        title: '游戏详情',
        index: 0
      }
    },
    {
        path: '/pc/index/login',
        name: 'Login',
        components: components(Login),
        meta:{
          title: '登录',
          index: 0
        }
      },
    // {
    //   path:'/changePhone',
    //   name:'ChangePhone',
    //   components:components(ChangePhone),
    //   meta:{
    //     title: '换绑手机',
    //     index: 0
    //   }
    // }
  ]
})
