<template>
  <transition name="popup-fade">
    <div
      v-show="show"
      class="vm-popup"
      @click.stop="clickFun('clickCancel')"
    >
      <div class="popup-content-wrap" @click.stop>
        <div class="popup-close-icon" @click="clickFun('clickCancel')">
          <img src="@/assets/login/close.png" alt="">
        </div>
        <div class="content">
          <h3 class="vm-content-title">{{ title }}</h3>
          <div class="popup-text">{{ content }}</div>
          <slot />
          <div v-if="btnName" class="popup-btn">{{ btnName }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    name: 'popup-components',
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        btnName: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: false
        },
        showClosebtn: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
        clickFun() {
            this.$emit('closePopup')
        }
    }
}
</script>
<style scoped  lang="scss">
    .vm-popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 998;
        display: flex;
        justify-content: center;
        align-items: center;
        /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 进入和出去的动画 */
    .popup-fade-enter-active {
        animation: opacity 0.3s;
        .popup-content-wrap {
            animation: scale 0.3s;
        }
    }
    .popup-fade-leave-active {
        animation: outOpacity 0.2s;
    }

    /* 包裹层容器样式 */
    .popup-content-wrap {
        position: absolute;
        min-width: 560px;
        margin: 0 auto;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 16px;
        z-index: 999;
        user-select: none;
    }
    .popup-close-icon {
        position: absolute;
        top: 9px;
        right: 9px;
        img {
            
            width: 32px;
            height: 32px;
        }
    }
    .content {
        margin: 48px;
        background: #fff;
        /* 顶部标题部分 */
        .vm-content-title {
            padding-top: 24px;
            font-size: 32px;
            font-weight: 500;
            color: #050c1c;
        }
    }
    .test-icon {
        width: 32px;
        height: 32px;
        color: #84888f;
    }
    .popup-text {
        text-align: center;
        font-size: 26px;
        color: #84888f;
        line-height: 1.5;
        max-height: 900px;
        overflow: hidden;
        overflow-y: scroll;
    }
    .popup-btn {
        width: 464px;
        height: 104px;
        opacity: 1;
        background: #00d1c9;
        border-radius: 52px;
        line-height: 104px;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        margin-top: 48px;
    }

    /* 顶部标题部分 */
    .vm-popup-title {
        padding-top: 24px;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        color: #050c1c;
    }

    /* 中间内容部分 */
    .vm-popup-content {
        padding: 18px 32px 32px 32px;
        text-align: center;
        font-size: 26px;
        color: #84888f;
        line-height: 1.5;
    }
    /* 底部按钮样式 */
    .vm-operation {
        line-height: 55px;
        display: flex;
        border-top: 1px solid #eee;
        font-size: 32px;
        .confirm-btn {
            color: #ffb000;
        }
    }
    .vm-operation .vm-cancel-btn, .confirm-btn {
        flex: 1;
    }
    .vm-operation .vm-btn-text {
        text-align: center;
        font-size: 32px;
        margin: 14px 0;
        padding: 6px 0;
    }

    /* 其他修饰样式 */
    .vm-border-right {
        border-right: 1px solid #eee;
    }

    /* 进来的动画 */
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes scale {
        0% {
            transform: scale(0);
        }
        60% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    /* 出去的动画 */
    @keyframes outOpacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>
