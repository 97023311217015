<template>
<div class="header-fixed">
  <div class="header">
    <div class="max-width clearfloat">
      <div class="header-left clearfloat">
        <div class="header-logo"><img src="@/assets/logo.png" alt=""></div>
        <div class="header-nav clearfloat">
          <div class="nav-item" v-for="(item,index) in list" :key="index"  @click="jump(item)">
            <div class="nav-item-text">{{item.text}}</div>
            <div class="nav-item-line" v-if="navIndex === index"></div>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="app-login">
            <span @click="gotoLogin">{{text}}</span>
            <span class="line">|</span>
            <span @click="showPopoup=true">充值</span>
        </div>
        <div class="app-download" @click="openAppD">
          <img src="@/assets/header/appd.png">下载APP
        </div>
      </div>
    </div>
    <popup-components v-show="showPopoup" @closePopup="showPopoup=false">
        <div>
            <div class="popup-title">充值限时优惠</div>
            <div class="popup-money">
                <p v-for="(item, index) in moneyList" :key="index" @click="selectMoney(index)" :class="['static', index === active ? 'active': '']">
                    <span class="money">{{item}}</span>
                    <span class="yuan">元</span>
                </p>
            </div>
            <div class="scan">
                <img src="@/assets/login/scan.png" />
            </div>
        </div>
    </popup-components>
  </div>
</div>
</template>

<script>
import popupComponents from '../common/popup/index'
export default {
  data () {
    return {
        number_czc:1,
        text: '登录',
        active: 0,
        showPopoup: false,
        moneyList: ['10', '20', '30', '40', '50', '60', '70', '80'],
        list:[
          {
            text:'首页',
            link:'/pc/index',
            data:'官网_首页_点击顶部导航栏”首页“'
          },
          {
            text:'关于我们',
            link:'/pc/about',
            data:'官网_首页_点击顶部导航栏”关于我们“'
          },
          // {
          //   text:'APP下载',
          //   link:'/app'
          // }
        ]
    }
  },
  components: {
      popupComponents
    },
  computed:{
    navIndex:function(){
      return parseInt(this.$route.meta.index)
    }
  },
  methods: {
    selectMoney(index) {
        this.active = index
    }, 
    jump(item){
      window.czc.push(['_trackEvent', '官网_首页', item.data, '', '', this.number_czc])
      this.number_czc++
      this.$router.push({path:item.link})
    },
    openAppD(){
      window.czc.push(['_trackEvent', '官网_首页', '官网_首页_点击顶部导航栏”下载App“', '', '', this.number_czc])
      this.number_czc++
      window.open('/pc/app')
    },
    gotoLogin() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
        if (userInfo.nick_name) return
        this.$router.replace({path: '/pc/index/login'})
    }
  },
  created(){
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    if (userInfo.nick_name) {
        this.text = userInfo.nick_name
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header-fixed{
  height: 74px;
  position: relative;
  z-index: 99;
}
.header{
  height: 74px;
  background: #ffffff;
  box-shadow: 0px 3px 30px 0px rgba(173,173,173,0.10); 
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}
.header-left{
  float: left;
  .header-logo{
    margin-top: 14px;
    float: left;
    width: 133px;
    height: 47px;
    margin-right: 110px;
    img{
      width: 100%;
    }
  }
  .header-nav{
    float: left;
    padding-top: 28px;
  }
}
.nav-item{
  float: left;
  margin-right: 60px;
  height: 46px;
  position: relative;
  cursor: pointer;
  .nav-item-text{
    color: #070d0f;
    font-weight: 600;
    font-size: 16px;
  }
  .nav-item-line{
    height: 6px;
    background: linear-gradient(139deg,#ff5a34 16%, #ee3747 81%);
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.header-right{
  float:right;
  display: flex;
  justify-content: space-between;
//   width: 250px;
  height: 74px;
  line-height: 74px;
}
.line {
    margin: 0 10px;
}
.app-login {
    cursor: pointer;
    margin-right: 10px;
}
.app-download{
  width: 117px;
  height: 34px;
  background: #ffebe6;
  border-radius: 4px;
  margin-top: 20px;
  line-height: 34px;
  color: #fe3a3d;
  font-size: 16px;
  position: relative;
  padding-left: 37px;
  box-sizing: border-box;
  cursor: pointer;
  img{
    position: absolute;
    left:13px;
    top:7px;
    width: 20px;
  }
}
.popup-content-wrap {
    width: 1076px;
    overflow: hidden;
}
.popup-title {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #EDEDEE;
}
.scan {
    margin-top: 20px;
    width: 1060px;
    img {
        width: 100%;
    }
}
.popup-money {
    margin-top: 20px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;
    // flex-wrap: wrap;
    width: 1076px;
    margin-left: -15px;
    overflow: hidden;
    clear: both;
    p {
        float: left;
        width: 253px;
        box-sizing: border-box;
        height: 127px;
        display: flex;
        align-items: center;
        padding: 0 84px;
        overflow: hidden;
        margin-left: 16px;
        margin-bottom: 16px;
        border-radius: 10px;
        color: #070D0F;
        background: #fff;
        .money {
            font-size: 56px;
        }
        .yuan {
            font-size: 24px;
            margin-top: 14px;
            margin-left: 5px;
        }
    }
    .static {
        border: 1px solid #EDEDEE;
    }
    .active {
        background: #FFEBE6;
        border: 1px solid hsl(1, 83%, 66%);
        color: #F44341;
    }
}
</style>
