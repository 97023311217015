<template>
  <div class="about-top">
    <span :class="navIndex == index ? 'alink on' : 'alink'" v-for="(item,index) in list" :key="index" @click="go(item)">{{item.text}}</span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      number_czc:1,
      list:[
        {
          text:'了解我们',
          link:'/pc/about',
          data:'官网_关于我们页面_点击“了解我们”'
        },
        {
          text:'联系我们',
          link:'/pc/about/contact',
          data:'官网_关于我们页面_点击“联系我们”'
        }
      ]
    }
  },
  computed:{
    navIndex:function(){
      return parseInt(this.$route.meta.nav)
    }
  },
  methods: {   
    go(item){
      window.czc.push(['_trackEvent', '官网_关于我们页面', item.data, '', '', this.number_czc])
      this.number_czc++
      this.$router.push({path:`${item.link}`})
    } 
  },
  created(){
  }
}
</script>

<style lang="scss" scoped>
.about-top{
    height: 98px;
    line-height: 97px;
    text-align: center;
    color: #070d0f;
    font-size: 16px;
    font-weight: 600;
  }
  .alink{
    margin: 0 40px;
    cursor: pointer;
    &:hover{
       color: #fe3a3d;
    }
  }
  .on{
    color: #fe3a3d;
  }
</style>
