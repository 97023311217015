<template>
  <el-dialog
    :title="`在橙子游戏APP下载【${name}】`"
    :visible.sync="isShow"
    :before-close="closeAppDown"    
    center
    width="500px">
    <div class="clearfloat">
      <div class="img-left">
        <img src="@/assets/orange.png" alt="">
        <p>扫码下载橙子游戏</p>
      </div>
      <div class="text-right">
        <p v-for="(item,index) in list" :key="index">{{item}}</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      list:[
        '· 海量游戏，充值5折起',
        '· 先玩后付，充值不愁钱',
        '· 平台权益，福利领不停',
        '· 游戏社区，陪你一起玩'
      ]
    }
  },
  props: {
    isShow: {
      type: Boolean
    },
    name: {
      type: String
    }
  },
  computed:{
    
  },
  methods: { 
    closeAppDown(){
      this.$emit('closeAppDown')
    }
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
  .img-left{
    float: left;
    width: 120px;
    height: 120px;
    margin: 9px 0 0 75px;
    font-size: 12px;
    color: #a2a9b3;
    text-align: center;
    img{
      width: 120px;
    }
    p{
      margin-top: 5px;
    }
  }
  .text-right{
    float: left;
    font-size: 14px;
    color: #070d0f;
    margin:12px 0 0 30px;
    p{
      margin-bottom:16px ;
    }
  }
      ::v-deep .el-dialog{
    border-radius: 12px;
  }
    ::v-deep .el-dialog__title{
    font-size: 22px;
    color: #070d0f;
  }
    ::v-deep .el-dialog__header{
    padding-top: 50px;
    padding-bottom: 20px;
    
    border-bottom: 1px solid #ededee;
  }
  ::v-deep .el-dialog__body{
    padding-bottom: 50px;
  }
</style>
