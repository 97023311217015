<template>
  <div class="detail" :style="{backgroundImage: 'url(' + bg_pic + ')'}">
    <div class="clearfloat max-width">
      <div class="detail-top"></div>
      <div class="detail-left">
        <div class="game-top clearfloat">
          <div class="game-ico">
            <img v-if="detail.pics && detail.pics.icon" :src="detail.pics.icon" alt="">
          </div>
          <div class="game-name">
            <div class="t clearfloat">
              <div class="t-1">{{detail.name}}</div>
              <div :class="detail.discount < 10 ? 't-2' : 't-3'" v-html="discountText(detail.discount,detail.label_extra_desc)"></div>
            </div>
            <div class="p">{{detail.brief}}</div>
            <div class="tag">
              <template v-for="(p,d) in detail.labels">
                <span :key="d" v-if="d < 4"><span v-if="d != 0"> / </span>{{p.name}}</span>
              </template>
            </div>
          </div>
          <div class="game-share">
            <div class="share-btn" @click="detailDownDialog=true">下载</div>
          </div>
        </div>
        <div class="game-f-left clearfloat">
          <div class="n">{{detail.ratings}}</div>
          <div class="startp">
            <div class="star"><img src="@/assets/detail/start.png" alt=""></div>
            <div class="star2" :style="{width:starWidth+'%'}"><img src="@/assets/detail/start2.png" alt=""></div>
          </div>
        </div>
        <div class="game-open">
          <dl class="dl-open">
            <dt>游戏开服<div v-if="openServerListAll.length > 4" class="dl-open-more" @click="showAllServe">全部<span>&gt;</span></div></dt>
            <dd :class="!showMoreServel ? 'clearfloat open-list' : 'clearfloat open-list-max'" v-if="detail.latest_server_info && detail.latest_server_info.server_name && detail.latest_server_info.server_id"><!--  -->
              <div :class="(k == 3 || k == 7 == k == 11) ? 'open-li open-li-no' :'open-li'" v-for="(p,k) in openServerList" :key="k">
                <div class="open-li-time clearfloat"><div class="time1" v-html="formatServerDate(p.start_time)"></div><div class="time2" v-if="isToday(p.start_time*1000)">今日</div></div>
                <div class="open-li-name">{{p.server_name}}</div>
              </div>
            </dd>
            <dd class="dl-open-no" v-else-if="detail.latest_server_info && detail.latest_server_info.description">{{detail.latest_server_info.description}}</dd>
            <dd v-else class="dl-open-no">
              暂无开服信息
            </dd>
          </dl>
        </div>
        <div class="diySwiper">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item,index) in fivePicList" :key="index">
              <img :src="item" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="game-desc">
          <dl class="dl-game">
            <dt>游戏详情</dt>
            <dd><pre class="detail-pre">{{detail.description}}</pre></dd>
          </dl>
        </div>
        <div class="game-pack">
          <table>
            <tr>
              <td class="td1">供应商</td>
              <td>{{detail.vendor}}</td>
              <td class="td1">大小</td>
              <td>{{detail.package_size_desc}}</td>
            </tr>
            <tr>
              <td class="td1">兼容性</td>
              <td>{{detail.compitable_desc}}</td>
              <td class="td1">版本更新</td>
              <td>{{formatDate(detail.version_time,1)}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="detail-right">
        <div class="rigth-top1 clearfloat">
          <div class="top1-left">
            <img src="@/assets/orange.png" alt="">
            <div class="t">下载橙子游戏APP</div>
            <div class="p">专业手游折扣平台</div>
          </div>
          <div class="top1right">
            <img src="@/assets/detail/appright.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <el-dialog
      title="全部开服信息"
      :visible.sync="dialogVisible"
      center
      width="30%">
      <div>
        <el-table
          :data="openServerListAll"
          stripe
          style="width: 100%">
          <el-table-column           
            label="开服时间">
            <template slot-scope="scope">
            <span v-html="formatServerDate(scope.row.start_time)"></span><span class="today-span" v-if="isToday(scope.row.start_time*1000)">今日</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="server_name"
            label="服务器"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> -->
    <app-down :isShow="detailDownDialog" :name="detail.name" @closeAppDown="closeAppDown"></app-down>
    <open-server :isShow="dialogVisible" :list="openServerListAll" @closeAppDown="closeOpenServer"></open-server>
  </div>  
</template>

<script>
import detail from './detail'
export default detail
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
