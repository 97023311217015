<template>
  <div class="about-pad">
    <Nav></Nav>
    <div class="about-contact max-width">
      <div class="about-img">
        <img class="img-auto" src="@/assets/about/a.png" alt="">
      </div>
      <p class="text-p">
        乐信（NASDAQ:LX）成立于2013年8月，是中国领先的新消费服务平台，以科技创新不断创造新消费方式，打造了以分期消费品牌分期乐、数字化全场景分期消费产品乐花卡、跨生态会员权益聚合平台乐卡为核心的新消费服务生态，帮助亿万用户快乐消费，通过科技助力商家提升经营能力，创造更大商业价值，助力中国新商业文明加快建设，推动中国经济健康蓬勃发展。截至2020年9月，乐信用户数达到1.06亿。
      </p>
      <p class="text-p"> 立足新人群、满足新需求、拓展新方式，乐信打造了集分期消费、会员消费、金融科技为一体的新消费服务生态，打通了广阔的线上线下场景。 </p>
      <p class="text-p">分期乐商城打造开放式新消费平台，拥有苹果、华为、欧莱雅等3000多个新消费品牌，打通淘宝、京东、拼多多等平台；乐花卡连接金融机构与线上线下消费场景，满足了用户线上线下便捷消费的需求，累计为600多万商家带去实际消费交易，用户数超1200万；乐卡APP已开拓线上线下4000多个品牌权益，涵盖餐饮生鲜、健身学习、旅游出行、生活服务、影音游戏、定制权益等，并加速推进“百千计划”，对接各类线上巨头、线下大型连锁机构以及社区、写字楼周边中小商铺，与线下国际零售巨头达成战略合作。
        </p>
      <p class="text-p">作为开放的新消费服务平台，乐信通过共享场景、技术、运营能力，扮演着新消费连接器的作用，吸引着越来越多公司加入，打造出一个稳固、开放、共赢的新消费生态，让新消费释放出最大增长潜力。2013年创办以来，乐信自身实现了指数级增长，年复合增长率达到200%，成为中国新消费行业的领军企业。</p>
      <p class="text-p">乐信还入选“中国服务业企业500强”、“中国互联网百强企业”、 “广东民营企业100强”、“深圳创新企业70强”、“南山区纳税百强”等榜单，并成功入选哈佛商学院案例，获得《亚洲银行家》等上百家媒体的众多荣誉及人民日报、央视、CNBC、彭博等国内外主流媒体关注。
      </p>
      <div class="logo-title">
        <img src="@/assets/about/orange.png" alt="">
      </div>
      <p class="text-p">乐信集团旗下的“橙子游戏”是植根于移动互联网的移动端游戏平台。该平台成立于中国互联网最繁荣的地区-深圳市南山区，其为全中国的游戏用户持续提供“稳定”“优质”“低价”的游戏娱乐体验。其功能主体为“折扣游戏”，即通过让利用户来获得用户的高度粘性。同时，在其系统功能内接入了中国大陆先进的信用支付能力，为广大游戏用户提供福利。 </p>
      <p class="text-p">橙子游戏拥有原生APP和H5平台两种模式，拥有平台系统和信用支付系统两类sdk。面对C端用户时，橙子游戏通过其平台向用户提供多快好省的服务。而当面对B端的游戏运营商和游戏发行商时，橙子游戏为其提供全国领先的信用支付方式和全套SDK接入方式，为b端用户持续赋能。</p>
      <div class="logo-title logo-title-fql">
        <img src="@/assets/about/fql.png" alt="">
      </div>
      <p class="text-p">分期乐是乐信（NASDAQ:LX）旗下品质分期购物商城，成立于2013年10月，是中国分期购物电商模式开创者，秉持“乐在有度、乐见更好”的品牌理念，是1亿新消费用户分期购物的信赖之选。</p>
      <p class="text-p">分期乐打造了开放式新消费平台，苹果、华为、OPPO、vivo、欧莱雅、飞利浦等众多知名品牌入驻分期乐，优质商家与优质内容结合，实现了“屏幕即店铺，内容即商品”；支持用户从分期乐商城跳转到淘宝、京东、拼多多等平台完成购物，大幅提高消费者获得感、喜悦感及商品与用户匹配效率。</p>
      <div class="logo-title logo-title-leka">
        <img src="@/assets/about/leka.png" alt="">
      </div>
      <p class="text-p">乐卡是乐信（NASDAQ:LX）旗下跨生态会员权益聚合平台，以会员消费和本地生活为核心，根据用户身份、兴趣、消费偏好及地理位置，通过领先科技打造服务体系，为用户推荐最适合其日常生活所需的品牌权益、积分通兑和专属会员服务，涵盖餐饮生鲜、读书学习、旅游出行、电商专区、生活服务、影音游戏、定制权益、联名会员等4000多个品牌权益，让消费者花更少的钱，过更好的生活。</p>
    </div>     
  </div>
</template>

<script>
import Nav from "./components/nav";
export default {
  name:'Index',
  components:{
    Nav
  },
  created(){
      window.czc.push(['_setAccount', window.ymId])
      window.czc.push(['_trackPageview', '官网_关于我们页面'])
  },
  methods:{
      
  }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>