import AppDown from "@/components/index/app.vue";
export default {
  name:'Index',
  data() {
    return {
      bannerList:[
        {
          slide_pic:require('@/assets/index/1834.png'),
          jump_type:3,
          jump_url:"1834"
        },
        {
          slide_pic:require('@/assets/index/1955.png'),
          jump_type:3,
          jump_url:"1955"
        },
        {
          slide_pic:require('@/assets/index/1970.png'),
          jump_type:3,
          jump_url:"1970"
        }
      ],
      blocks:[],
      number_czc:1,
      bannderIndex:0
    };
  },
  components:{
    AppDown
  },
  watch: {     
    '$route.path':function(newVal,oldVal){
      console.log(newVal != oldVal);
      this.bannderIndex = 2
    }
  }, 
  created(){
    this.getDataTest()
    window.czc.push(['_setAccount', window.ymId])
    window.czc.push(['_trackPageview', '官网_首页'])
  },
  computed: {
    goodList:function(){
      if(this.blocks.length > 0){
        let arr = this.blocks.filter((item)=>{
          return item.title == '编辑精选'
        })
        let obj = arr[0]
        let games = obj.games.slice(0,4)
        obj.games = games
        return obj
      }else{
        return {}
      }
    },
    newGameList:function(){
      if(this.blocks.length > 0){
        let arr = this.blocks.filter((item)=>{
          return item.title == '新游首发'
        })
        let obj = arr[0]
        let games = obj.games
        let result = []
        let chunk = 4
        for (var i = 0, j = games.length; i < j; i += chunk) {
            result.push(games.slice(i, i + chunk));
        }
        obj.gameList = result
        return obj
      }else{
        return {}
      }
    },
    otherList:function(){
      if(this.blocks.length > 0){
        let arr = this.blocks.filter((item,index)=>{
          return index > 2
        })
        return arr
      }else{
        return []
      }
    },
  },
  methods:{
    bannerGo(item){
      window.czc.push(['_trackEvent', '官网_首页', '官网_首页_点击运营banner', '', '', this.number_czc])
      this.number_czc++
      if(item.jump_type == 2){ // h5
        window.open(item.jump_url)
      }else if(item.jump_type == 3){ // 游戏详情
        this.goDetail(item.jump_url)
      }else if(item.jump_type == 1){ //空白
        return
      }
    },
    goDetailOther(id,index,k){
      let data = ''
      if(k == 0){
        data = `官网_首页_推荐专题${index+1}-主推荐位点击游戏`
      }else{
        data = `官网_首页_推荐专题${index+1}-${k+1}-副推荐位点击游戏`
      }
      window.czc.push(['_trackEvent', '官网_首页', data, '', '', this.number_czc])
      this.number_czc++
      this.$router.push({path:`/pc/detail/${id}`})
    },
    goDetail(id,flag){
      // flag 1  新游首发  2 编辑精选
      let data = ''
      if(flag == 1){
        data = '官网_首页_新游首发点击游戏'
      }else if(flag == 2){
        data = "官网_首页_编辑精选点击游戏"
      }
      window.czc.push(['_trackEvent', '官网_首页', data, '', '', this.number_czc])
      this.number_czc++
      this.$router.push({path:`/pc/detail/${id}`})
    },
    getDataTest() {
      this.$fetch('/index.php?g=api&m=game&a=index&system=1')
      .then((response) => {
        let data = response.data
        // let bannerList = data.banners.fixed
        // this.bannerList = bannerList.filter((i) => {
        //   return i.jump_type ==2 || i.jump_type ==3 || i.jump_type ==1;
        // });
        this.blocks = data.blocks
      })
    },
    discountText(discount,label_extra_desc){
      if(discount < 10){
        return `<span class="font-family-din">${discount}</span>折充值`        
      }else{
        if(label_extra_desc){
          return label_extra_desc
        }else{
          return `<span class="font-family-din">${discount}</span>折充值`
        }
      }
    }

  }
}