import Vue from "vue";
import "babel-polyfill";
import { Message, Dialog, Carousel, CarouselItem, Popover, Table, TableColumn } from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css'
import App from "./App.vue";
import router from "./router";
import { post, fetch } from "@/api/request";
import "vue2-toast/lib/toast.css";
import Toast from "vue2-toast";
import VueLazyload from "vue-lazyload";
import "./api";

Vue.config.productionTip = false;

Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.use(VueLazyload);
//Vue.use(Message);
Vue.use(Toast, {
  type: "center",
  wordWrap: true
});
Vue.use(Dialog);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Popover);
Vue.use(Table);
Vue.use(TableColumn);
Vue.prototype.$message = Message;
// new Vue({
//   render: h => h(App),
// }).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = `${to.meta.title} - 海南爱喵游网络科技有限公司`;
  }
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

var vueApp = new Vue({
  el: "#app",
  router,
  components: { App },
  template: "<App/>"
});
export default vueApp;
