<template>
  <div class="about-pad" style="margin-top:40px">
    <div class="about-contact max-width">
      <img class="img-auto" src="@/assets/about/license.png" alt="">
    </div>     
  </div>
</template>

<script>

export default {
  name:'Index',
  components:{
  },
  created(){
      
  },
  methods:{
      
  }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>