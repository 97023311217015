<template>
  <div class="about-pad">
    <Nav></Nav>
    <div class="about-contact max-width">
      <dl>
        <dt>广告投放</dt>
        <dd>联系邮箱：kingli@lexin.com</dd>
      </dl>
      <dl>
        <dt>商务合作</dt>
        <dd>联系邮箱：kingli@lexin.com</dd>
        <dd>公司地址：广东省深圳市南山区科苑南路3099号中国储能大厦第23层</dd>
      </dl>
    </div>
  </div>
  
</template>

<script>
import Nav from "./components/nav";
export default {
    name:'Contact',
    components:{
      Nav
    },
    created(){
       
    },
    methods:{
        
    }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>