<template>
  <div class="commonlogin">
    <div class="commonlogin-content">
        <!-- <div class="common-login-back" @click="close" /> -->
    <div class="common-login-header">欢迎来到游戏中心</div>
    <div class="common-login-form">
      <div class="common-login-li">
        <div class="login-li-label">
          +86
        </div>
        <div class="login-li-phone">
          <input
            v-model="mobile"
            type="text"
            placeholder="请输入手机号"
          >
        </div>
        <div
          v-if="mobile != ''"
          class="phone-close"
          @click="clearPhone"
        />
      </div>
      <div id="captcha-box" class="common-login-li">
        <div class="login-li-phone">
          <input
            v-model="sns"
            type="text"
            placeholder="请输入验证码"
          >
        </div>
        <div
          v-if="!showSnsTime"
          :class="mobile != '' ? 'login-li-sns' : 'login-li-sns login-li-snsgray'"
          @click="checkLogin"
        >获取验证码</div>
        <div
          v-else
          class="login-li-sns login-li-snsgray"
        >重新发送({{ snsTime }})</div>
      </div>
      <div class="common-login-p">未注册的手机号验证后通过自动创建登录帐号</div>
      <div :class="mobile != '' && sns != '' ? 'common-login-btn' : 'common-login-btn common-login-btnDisabled'" @click="beforeLogin"><img src="@/assets/login/jt.png" alt=""></div>
    </div>

    <div class="common-login-foot">
      <div class="login-foot-text">用户须知：游戏用户需完成平台账号注册登录后，才能够进行游戏体验，并结合国家网络游戏管理规范要求，完成实名登记</div>
      <div class="login-foot-a">登录即表示你同意<a @click.stop="goXy('//sdk.25wanwan.com//H5sdk/register_agreement.html','用户注册协议')">《用户注册协议》</a>
        <a @click.stop="goXy('//sdk.25wanwan.com//H5sdk/message_protect.html','橙子游戏个人信息保护协议')">《游戏个人信息保护协议》</a><a @click.stop="goXy('//sdk.25wanwan.com//H5sdk/children_protect.html','橙子游戏儿童个人信息保护指引')">《游戏儿童个人信息保护指引》</a></div>
    </div>
    <div
      v-if="iframe.show"
      class="mask"
      style="background: rgba(0, 0, 0, 0.2);position: fixed"
    >
      <div class="login-wrap">
        <div class="iframe-style">
          <div class="close-if" @click="iframe.show = false" />
          <div class="t">{{ iframe.title }}</div>
          <div class="iframe-bg">
            <iframe
              :src="iframe.url"
              frameborder="0"
              scrolling="auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
</template>

<script>
import md5 from 'js-md5'
export default {
    name: 'CommonLogin',
    components: {
    },
    data() {
        return {
            mobile: '',
            sns: '',
            showSnsTime: false,
            timerSms: null,
            snsTime: 60,
            sendMessageFlag: false,
            lexinCaptcha: null,
            param: {}, // 二级验证参数
            isLoading: false,
            captchaCode: '',
            isReg: false, // 是否已经注册过
            iframe: {
                url: '',
                show: false,
                title: ''
            }
        }
    },
    props: {
        gameid: {
            type: Number,
            default: 0
        },
        channel: {
            type: Number,
            default: 10
        }
    },
    watch: {

    },
    methods: {
        clearPhone() {
            this.mobile = ''
        },
        checkLogin() {
            const mobileReg = /^1[3,4,5,6,7,8,9][0-9]{9}$/
            if (!mobileReg.test(this.mobile)) {
                this.$toast.center('手机号码格式错误，请重新输入')
                this.mobile = ''
                return
            }
            if (this.isLoading) { // 防止用户重复点击
                this.$toast.center('获取验证失败，请重试')
                return false
            }
            this.isLoading = true
            this.lexincaptchaInit()
            this.checkUserExist()
        },
        checkUserExist() {
            const data = {
                mobile: this.mobile
            }
            this.$services.checkUserExist({
                method: 'post',
                data: data
            }).then(res => {
                if (res.code === '0') {
                    this.isReg = res.data.exist
                    console.log(res.data.exist)
                } else {
                    this.$toast.center(res.msg)
                }
            })
        },
        lexincaptchaInit() {
            var _this = this
            try {
                _this.lexinCaptcha = new window.LexinCaptcha({
                    el: '#captcha-box',
                    appId: '91381eccf932656be58ecb539e830999',
                    width: '',
                    areaId: '',
                    user_id: '',
                    client_type: 'h5',
                    // 错误
                    onError: function (data) {
                        var code = data.code
                        // 2, 310000, 51000 错误必须判断
                        if (code === 2 || code === 310000 || code === 510000) { // 绕过供应商行为认证直接执行业务逻辑
                            if (data.result) { // 获取灰度ID
                                _this.param = data.result
                            }
                            _this.checkVerifyCaptchaCode()
                        } else { // 提醒用户刷新验证
                            _this.$toast.center('获取验证失败，请重试')
                        }
                        _this.isLoading = false
                    },
                    // 初始化成功
                    onReady: function (data) {
                        console.log(data)
                        this.verify()
                        _this.isLoading = false
                    },
                    // 行为验证成功
                    onSuccess: function (data) {
                        _this.param = data.result // 二次验证参数
                        _this.checkVerifyCaptchaCode()
                        _this.isLoading = false
                    }
                })
            } catch (error) {
                _this.isLoading = false
            }
        },
        goXy(url, title) {
            this.iframe.title = title
            this.iframe.url = url
            this.iframe.show = true
        },
        checkVerifyCaptchaCode() {
            const data = this.param
            data.client_type = 'h5'
            this.$services.checkVerifyCaptchaCode({
                method: 'post',
                data: data
            }).then(res => {
                if (res.code === '0') {
                    this.captchaCode = res.data.token
                    this.sendMessage()
                } else {
                    this.$toast.center(res.msg)
                }
            })
        },
        sendMessage() {
            const data = {
                mobile: this.mobile,
                type: this.isReg ? 5 : 1,
                token: this.captchaCode
            }
            this.$services.sendMessage({
                method: 'post',
                data: data
            }).then(res => {
                if (res.code === '0') {
                    this.$toast.center('验证码已发送，请注意查收')
                    this.showSnsTime = true
                    this.timerSms = setInterval(() => {
                        if (this.snsTime > 0) {
                            this.snsTime--
                        } else {
                            this.snsTime = 60
                            this.showSnsTime = false
                            this.sendMessageFlag = false
                            clearInterval(this.timerSms)
                        }
                    }, 1000)
                } else if (res.msg) {
                    this.$toast.center(res.msg)
                    this.sendMessageFlag = false
                }
            })
        },
        beforeLogin() {
            if (this.mobile == '' || this.sns == '') return
            if (this.isReg) {
                // 登录
                this.loginViaVerifyCode()
            } else {
                this.register()
            }
        },
        loginViaVerifyCode() {
            const channel = this.channel
            const data = {
                channel,
                code: this.sns,
                machine_code: 1,
                mobile: this.mobile,
                system: 4,
                appid: 1001
            }
            this.$services.loginViaVerifyCode({
                method: 'post',
                data: data
            }).then(async res => {
                if (res && res.code === '0') {
                    const obj = res.data
                    localStorage.setItem('userInfo', JSON.stringify(obj))
                    window.location.href = 'https://box.25wanwan.com/pc/index'
                } else {
                    this.$toast.center(res.msg)
                }
            })
        },
        register() {
            const appid = localStorage.getItem('thirdAppID')
            const channel = this.channel
            const data = {
                appid,
                code: this.sns,
                mobile: this.mobile,
                password: md5(Math.random().toString(36).slice(-6) + 'lexingame').toLowerCase(),
                channel,
                system: 4,
                maker: 1,
                mobile_model: 1,
                machine_code: 1,
                system_version: '1',
                password_set: 2 // 2 表明是客户端随机设置的密码，如果用户主动设置的密码 就传 password_set =1
            }
            this.$services.register({
                method: 'post',
                data: data
            }).then(res => {
                if (res.code === '0') {
                    const sessionObj = res.data
                    localStorage.setItem('userInfo', JSON.stringify(sessionObj))
                    this.$router.replace({path: '/pc/index'})
                } else {
                    this.$toast.center(res.msg)
                }
            })
        }
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>
.commonlogin {
    padding-top: 100px;
    background:url(~@/assets/login/login-top.png) no-repeat #fff;
    background-size: 100%;
    
}
.commonlogin-content {
    width: 750px;
    height: 1000px;
    margin:  0 auto;
}

.common-login-header{
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color: #050c1c;
}
.common-login-form{
    padding:80px 120px 0;
    position:relative;
    z-index: 3;
}
.common-login-p{
    color: #84888f;
    font-size: 24px;
    text-align: center;
    padding-top: 20px;
}
.common-login-btn{
    width: 128px;
    height: 128px;
    background: linear-gradient(139deg,#44f2ed 15%, #00d1c9 85%);
    border-radius: 50%;
    margin: 56px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
    img{
        width: 42px;
        height: 36px;
    }
}
.common-login-btnDisabled{
    background: #99ede9;
    img{
        opacity: .7;
    }
}
.common-login-li{
    margin-bottom: 36px;
    height: 108px;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
	&:after{
		content: "  ";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: #ededee;
		/* 如果不用 background-color, 使用 border-top:1px solid #ccc; */
		-webkit-transform: scaleY(.5);
		transform:scaleY(.5);
	}
    .login-li-label{
        color: #050c1c;
        font-size: 36px;
        margin-right: 60px;
        font-weight: bold;
    }
    .phone-close{
        width: 40px;
        height: 40px;
        background:url(~@/assets/login/clear.png) no-repeat;
        background-size: 100%; 
    }
    .login-li-phone{
        flex:1;
        input{
            color: #050c1c;
            font-size: 36px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            outline: 0 none;
            border: 0;
            width: 100%;
            background:none;
            outline: none;
            border: 0;
        }
        input::-webkit-input-placeholder{
            color:#abadb2;
            font-weight: normal;
            font-size: 32px;
        }
        input::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color:#abadb2;
            font-weight: normal;
            font-size: 32px;
        }
        input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
            color:#abadb2;
            font-weight: normal;
            font-size: 32px;
        }
        input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
            color:#abadb2;
            font-weight: normal;
            font-size: 32px;
        }
    }
    .login-li-sns{
        color: #00d1c9;
        font-size: 32px;
        font-weight: normal;
    }
    .login-li-snsgray{
        color: #abadb2;
        cursor: pointer
    }
}
.common-login-foot{
    z-index: 1;
    font-size: 24px;
    a{
        color: #00D1C9;
    }
    .login-foot-text{
        background: #f7f7f7;
        border-radius: 24px;
        padding:36px;
        margin:  50px;
        font-size: 24px;
        color: #84888f;
        margin-bottom: 40px;
        line-height: 1.5;
    }
    .login-foot-a{
        padding:0 50px;
        font-size: 20px;
        line-height: 30px;
    }
}
.iframe-style{
    padding:48px;
    position: relative;
    height: 680px;
    overflow: hidden;
    .close-if{
        background-image:url("~@/assets/login/close.png");
        background-size: 100%;
        width: 48px;
        height: 48px;
        position:absolute;
        top:24px;
        right:24px;
    }
    .t{
        font-size: 32px;
        color: #070d0f;
        margin-bottom: 32px;
        font-weight: bold;
    }
    .iframe-bg{
        height:590px;
        overflow:scroll;
    }
    iframe{
        width: 100%;
        height:590px;
    }
}
  .mask{
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom:0;
	z-index:100;
	background: rgba(0,0,0,.6);
}
.login-wrap{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 590px;
    background: #ffffff;
    border-radius: 24px;
}
</style>
